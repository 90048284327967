import { readonly, ref } from 'vue';
import { defineStore } from 'pinia';
import {
  api,
  ISetProfileRequest,
  ITgWebAppSignInRequest,
  IProfile,
  ISetLocationRequest,
} from '@/api/auth';
import { RequestErrorDetails } from '@/api';

export const useAuthStore = defineStore('auth', () => {
  const lang = ref('');
  const profile = ref<IProfile>();

  const tgWebAppSignIn = async (
    payload: ITgWebAppSignInRequest
  ): Promise<boolean> => {
    const resp = await api.tgWebAppSignIn(payload);
    if (resp.ok) {
      lang.value = resp.payload.lang;
    }
    return resp.ok;
  };

  const loadProfile = async () => {
    const resp = await api.getProfile({});
    if (resp.ok) {
      profile.value = resp.payload;
    }

    return resp;
  };

  const updateProfile = async (
    data: ISetProfileRequest
  ): Promise<RequestErrorDetails[]> => {
    const resp = await api.setProfile(data);
    if (!resp.ok) {
      return resp.error;
    }

    profile.value = resp.payload;
    return [];
  };

  const updateOnline = async () => {
    await api.setOnline({});
  };

  const shareLocation = async () => {
    await api.shareLocation({});
  };

  const setLocation = async (data: ISetLocationRequest): Promise<boolean> => {
    const resp = await api.setLocation(data);
    return resp.ok;
  };

  const getLanguageCode = (): string => {
    return lang.value;
  };

  const setLanguageCode = async (code: string) => {
    const resp = await api.setLanguage({ lang: code });
    if (resp.ok) {
      lang.value = resp.payload.lang;
    }
  };

  const deleteAccount = async (): Promise<string> => {
    const resp = await api.deleteAccount({});
    if (resp.ok) {
      return resp.payload.taskId;
    }
    return '';
  };

  return {
    profile: readonly(profile),
    tgWebAppSignIn,
    loadProfile,
    updateProfile,
    getLanguageCode,
    setLanguageCode,
    updateOnline,
    shareLocation,
    setLocation,
    deleteAccount,
  };
});
