import { computed, defineComponent, ref } from 'vue';
import styles from './index.css?module';
import { Icon } from '@/components/ui';

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
    iconName: {
      type: String,
    },
    showClearBtn: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
    },
    onClear: {
      type: Function,
    },
  },

  setup(props, { slots, emit }) {
    const isOpen = ref(false);
    const isTouchActive = ref(false);
    const isActive = computed(() => {
      return props.isActive || isTouchActive.value;
    });

    const textStyle = computed(() => {
      return 'text-textPrimary';
    });

    const onTouchStart = () => {
      isTouchActive.value = true;
    };
    const onTouchEnd = () => {
      isTouchActive.value = false;
    };

    const onOpen = async () => {
      if (typeof props.onClick !== 'undefined') {
        props.onClick();
        return;
      }
      isOpen.value = true;
    };

    const onClose = () => {
      isOpen.value = false;
    };

    const onClear = (e: Event) => {
      e.stopPropagation();
      emit('clear');
    };

    return () => (
      <>
        <div
          class={[styles.FilterItem, isActive.value && styles.FilterItemActive]}
          onClick={onOpen}
          onTouchstart={onTouchStart}
          onTouchend={onTouchEnd}
        >
          {props.iconName && (
            <div class={'mr-2'}>
              <img
                class={'w-5 h-5'}
                src={require(`@/assets/images/${props.iconName}.png`)}
                alt=""
              />
            </div>
          )}
          <div class={['truncate max-w-[15rem]', textStyle.value]}>
            {props.name}
          </div>
          {props.showClearBtn && (
            <div class={styles.ClearBtn} onClick={onClear}>
              <Icon class={styles.ClearBtnIcon} name={'close'} />
            </div>
          )}
        </div>

        {slots?.modal?.(isOpen.value, onClose)}
      </>
    );
  },
});
