import { defineComponent, PropType } from 'vue';
import styles from './index.css?module';
import { IAdInfo } from '@/api/ad';
import { AdCard } from '@/components/ads';
import { LazyPhoto } from '@/components/ads/card/Card';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import { SlideData } from 'photoswipe';
import { useS3 } from '@/composables';

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<Readonly<IAdInfo[]>>,
      required: true,
    },
    showDislikeBtn: {
      type: Boolean,
      default: false,
    },
    onOpenGallery: {
      type: Function,
    },
    onCloseGallery: {
      type: Function,
    },
  },

  setup(props, { emit }) {
    const media = useS3();

    const overflow = 2;
    const lightbox = new PhotoSwipeLightbox({
      pswpModule: () => import('photoswipe'),
      zoom: false,
      close: false,
      bgOpacity: 1,
      showAnimationDuration: 200,
      hideAnimationDuration: 200,
    });

    lightbox.on('afterInit', () => {
      document.body.style.height = window.innerHeight + 100 + 'px';
      window.scrollTo(0, overflow);
      emit('openGallery');
    });

    lightbox.on('close', () => {
      document.body.removeAttribute('style');
      window.scrollTo(0, -2);
      emit('closeGallery');
    });

    const openGallery = (photos: LazyPhoto[], index: number) => {
      const dataSource = photos.map((photo) => {
        return {
          src: media.original(photo.url),
          width: photo.meta.width,
          height: photo.meta.height,
        };
      }) as SlideData[];

      lightbox.loadAndOpen(index, dataSource);
    };

    return () => (
      <div class={styles.AdListWrapper}>
        {props.items.map((item) => {
          return (
            <AdCard
              key={`card-${item.id}`}
              item={item}
              showDislikeBtn={props.showDislikeBtn}
              onOpenGallery={openGallery}
            />
          );
        })}
      </div>
    );
  },
});
