import { defineComponent, PropType, computed, ref } from 'vue';
import styles from './index.css?module';
import { useDictionaryStore } from '@/store';
import { useI18n } from 'vue-i18n';
import {
  LazyDictionary,
  Dictionary,
  DictionaryValue,
  CountryFlags,
  Modal,
  DESIRE_ICONS,
} from '@/components/ui';
import {
  FilterItem,
  RangeFilter,
  RangeValue,
} from '@/components/ads/filter/index';
import { DistanceType } from '@/api/dictionary';

export interface FilterData {
  city?: DictionaryValue;
  desire?: DictionaryValue;
  age?: RangeValue;
  height?: RangeValue;
  newest?: boolean;
  hasPhoto?: boolean;
  isOnline?: boolean;
  withMatch?: boolean;
}

export default defineComponent({
  props: {
    modelValue: {
      type: Object as PropType<FilterData>,
    },
    showDistanceFilters: {
      type: Boolean,
      default: false,
    },
    onUpdatedButtonSate: {
      type: Function as PropType<() => void>,
    },
    onClear: {
      type: Function as PropType<() => void>,
    },
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const slider = ref(null);
    const dictionaryStore = useDictionaryStore();

    const defaultCity = {
      key: '0',
      name: t('component.filter.cityFilterName'),
    } as DictionaryValue;

    const defaultDesire = {
      key: '',
      name: t('component.filter.desireFilterName'),
    } as DictionaryValue;

    const defaultAge = {
      from: 18,
      to: 99,
      name: t('component.filter.ageFilterName'),
    } as RangeValue;

    const defaultHeight = {
      from: 100,
      to: 300,
      name: t('component.filter.heightFilterName'),
    } as RangeValue;

    const onClearFilters = () => {
      if (slider.value !== null) {
        const el = slider.value as HTMLElement;
        el.scrollTo({ left: 0, behavior: 'smooth' });
      }
      emit('clear');
    };

    const syncModelCity = computed({
      get(): DictionaryValue {
        if ((props.modelValue?.city?.name ?? '').length <= 0) {
          return defaultCity;
        }
        return props.modelValue?.city as DictionaryValue;
      },
      set(newVal: DictionaryValue) {
        emit(
          'update:modelValue',
          Object.assign({}, props.modelValue, {
            city: newVal,
          })
        );
      },
    });

    const applyCityFilter = (val: DictionaryValue, close: () => void) => {
      syncModelCity.value = val;
      close();
    };

    const clearCityFilter = () => {
      syncModelCity.value = defaultCity;
    };

    const syncModelDesire = computed({
      get(): DictionaryValue {
        if ((props.modelValue?.desire?.name ?? '').length <= 0) {
          return defaultDesire;
        }
        return props.modelValue?.desire as DictionaryValue;
      },
      set(newVal: DictionaryValue) {
        emit(
          'update:modelValue',
          Object.assign({}, props.modelValue, {
            desire: newVal,
          })
        );
      },
    });

    const applyDesireFilter = (val: DictionaryValue, close: () => void) => {
      syncModelDesire.value = val;
      close();
    };

    const clearDesireFilter = () => {
      syncModelDesire.value = defaultDesire;
    };

    const syncModelAge = computed({
      get(): RangeValue {
        const from = props.modelValue?.age?.from ?? 0;
        const to = props.modelValue?.age?.to ?? 0;
        if (from <= 0 && to <= 0) {
          return defaultAge;
        }

        return {
          from: from,
          to: to,
          name: `${from} - ${to}`,
        } as RangeValue;
      },
      set(newVal: RangeValue) {
        const from = newVal?.from ?? 0;
        const to = newVal?.to ?? 0;
        if (from != 0 || to != 0) {
          newVal.name = `${from} - ${to}`;
        }

        emit(
          'update:modelValue',
          Object.assign({}, props.modelValue, {
            age: newVal,
          })
        );
      },
    });

    const applyAgeFilter = (val: RangeValue, close: () => void) => {
      syncModelAge.value = val;
      close();
    };

    const clearAgeFilter = () => {
      syncModelAge.value = {} as RangeValue;
    };

    const syncModelHeight = computed({
      get(): RangeValue {
        const from = props.modelValue?.height?.from ?? 0;
        const to = props.modelValue?.height?.to ?? 0;
        if (from <= 0 && to <= 0) {
          return defaultHeight;
        }

        return {
          from: from,
          to: to,
          name: `${from} - ${to}`,
        } as RangeValue;
      },
      set(newVal: RangeValue) {
        const from = newVal?.from ?? 0;
        const to = newVal?.to ?? 0;
        if (from != 0 || to != 0) {
          newVal.name = `${from} - ${to}`;
        }

        emit(
          'update:modelValue',
          Object.assign({}, props.modelValue, {
            height: newVal,
          })
        );
      },
    });

    const applyHeightFilter = (val: RangeValue, close: () => void) => {
      syncModelHeight.value = val;
      close();
    };

    const clearHeightFilter = () => {
      syncModelHeight.value = {} as RangeValue;
    };

    const syncModelHasPhoto = computed({
      get(): boolean {
        return props.modelValue?.hasPhoto ?? false;
      },
      set(newVal: boolean) {
        emit(
          'update:modelValue',
          Object.assign({}, props.modelValue, {
            hasPhoto: newVal,
          })
        );
      },
    });

    const toggleHasPhotoFilter = () => {
      syncModelHasPhoto.value = !syncModelHasPhoto.value;
    };

    const clearHasPhotoFilter = () => {
      syncModelHasPhoto.value = false;
    };

    const syncModelIsOnline = computed({
      get(): boolean {
        return props.modelValue?.isOnline ?? false;
      },
      set(newVal: boolean) {
        emit(
          'update:modelValue',
          Object.assign({}, props.modelValue, {
            isOnline: newVal,
          })
        );
      },
    });

    const toggleIsOnlineFilter = () => {
      syncModelIsOnline.value = !syncModelIsOnline.value;
    };

    const clearIsOnlineFilter = () => {
      syncModelIsOnline.value = false;
    };

    const syncModelNewest = computed({
      get(): boolean {
        return props.modelValue?.newest ?? false;
      },
      set(newVal: boolean) {
        emit(
          'update:modelValue',
          Object.assign({}, props.modelValue, {
            newest: newVal,
          })
        );
      },
    });

    const toggleNewestFilter = () => {
      syncModelNewest.value = !syncModelNewest.value;
    };

    const clearNewestFilter = () => {
      syncModelNewest.value = false;
    };

    const syncModelWithMatch = computed({
      get(): boolean {
        return props.modelValue?.withMatch ?? false;
      },
      set(newVal: boolean) {
        emit(
          'update:modelValue',
          Object.assign({}, props.modelValue, {
            withMatch: newVal,
          })
        );
      },
    });

    const toggleWithMatchFilter = () => {
      syncModelWithMatch.value = !syncModelWithMatch.value;
    };

    const clearIsWithMatchFilter = () => {
      syncModelWithMatch.value = false;
    };

    const onLoadCities = async (
      q: string,
      page: number
    ): Promise<DictionaryValue[]> => {
      const items = await dictionaryStore.searchCityList(q, page);
      let result = items.map((item) => {
        return {
          key: item.id,
          name: item.name,
          caption: item.country?.name,
          captionImg: CountryFlags[item.country?.code],
        } as DictionaryValue;
      });

      if (q.length <= 0 && page <= 1 && props.showDistanceFilters) {
        result = [
          {
            key: DistanceType.Nearby,
            name: t('component.filter.cityFilterNearbyName'),
            caption: t('component.filter.cityFilterNearbyCaptionName'),
            captionImg: 'fire',
          },
          {
            key: DistanceType.Neighbors,
            name: t('component.filter.cityFilterNeighborsName'),
            caption: t('component.filter.cityFilterNeighborsCaptionName'),
            captionImg: 'neighbors',
          },
          {
            key: DistanceType.NotFarAway,
            name: t('component.filter.cityFilterNotFarAwayName'),
            caption: t('component.filter.cityFilterNotFarAwayCaptionName'),
            captionImg: 'not_far_away',
          },
          {
            key: DistanceType.OnTheHorizon,
            name: t('component.filter.cityFilterOnTheHorizonName'),
            caption: t('component.filter.cityFilterOnTheHorizonCaptionName'),
            captionImg: 'on_the_horizon',
          },
          ...result,
        ];
      }

      return result;
    };

    const onLoadDesires = async (): Promise<DictionaryValue[]> => {
      const items = await dictionaryStore.getDesireList();
      return items.map((item) => {
        return {
          key: item.slug,
          name: item.name,
          icon: DESIRE_ICONS[item.slug],
        } as DictionaryValue;
      });
    };

    const updatedButtonState = () => {
      emit('updatedButtonSate');
    };

    return () => (
      <div ref={slider} class={styles.FilterWrapper}>
        <FilterItem
          name={syncModelCity.value.name}
          iconName={'compass'}
          isActive={syncModelCity.value.key != '0'}
          showClearBtn={syncModelCity.value.key != '0'}
          onClear={clearCityFilter}
        >
          {{
            modal: (isOpen: boolean, onClose: () => void) => (
              <Modal
                isOpen={isOpen}
                isBodyFixed={false}
                onClose={onClose}
                onClosed={updatedButtonState}
              >
                {{
                  content: () => (
                    <LazyDictionary
                      selectedItem={syncModelCity.value}
                      onChecked={(v) => applyCityFilter(v, onClose)}
                      loadDictionaryFn={onLoadCities}
                    />
                  ),
                }}
              </Modal>
            ),
          }}
        </FilterItem>

        <FilterItem
          name={syncModelDesire.value.name}
          isActive={syncModelDesire.value.key != ''}
          showClearBtn={syncModelDesire.value.key != ''}
          onClear={clearDesireFilter}
        >
          {{
            modal: (isOpen: boolean, onClose: () => void) => (
              <Modal
                isOpen={isOpen}
                isBodyFixed={false}
                onClose={onClose}
                onClosed={updatedButtonState}
              >
                {{
                  content: () => (
                    <Dictionary
                      selectedItem={syncModelDesire.value}
                      onChecked={(v) => applyDesireFilter(v, onClose)}
                      loadDictionaryFn={onLoadDesires}
                    />
                  ),
                }}
              </Modal>
            ),
          }}
        </FilterItem>

        <FilterItem
          name={syncModelAge.value.name}
          isActive={syncModelAge.value.name != defaultAge.name}
          showClearBtn={syncModelAge.value.name != defaultAge.name}
          onClear={clearAgeFilter}
        >
          {{
            modal: (isOpen: boolean, onClose: () => void) => (
              <Modal
                isOpen={isOpen}
                isBodyFixed={false}
                onClose={onClose}
                onClosed={updatedButtonState}
              >
                {{
                  content: () => (
                    <RangeFilter
                      v-model={syncModelAge.value}
                      onApply={(v) => applyAgeFilter(v, onClose)}
                      description={t('component.filter.ageDescription')}
                    />
                  ),
                }}
              </Modal>
            ),
          }}
        </FilterItem>

        <FilterItem
          name={syncModelHeight.value.name}
          isActive={syncModelHeight.value.name != defaultHeight.name}
          showClearBtn={syncModelHeight.value.name != defaultHeight.name}
          onClear={clearHeightFilter}
        >
          {{
            modal: (isOpen: boolean, onClose: () => void) => (
              <Modal
                isOpen={isOpen}
                isBodyFixed={false}
                onClose={onClose}
                onClosed={updatedButtonState}
              >
                {{
                  content: () => (
                    <RangeFilter
                      v-model={syncModelHeight.value}
                      onApply={(v) => applyHeightFilter(v, onClose)}
                      description={t('component.filter.heightDescription')}
                    />
                  ),
                }}
              </Modal>
            ),
          }}
        </FilterItem>

        <FilterItem
          name={t('component.filter.hasPhotoFilterName')}
          isActive={syncModelHasPhoto.value}
          onClick={toggleHasPhotoFilter}
          onClear={clearHasPhotoFilter}
        />

        <FilterItem
          name={t('component.filter.newestFilterName')}
          isActive={syncModelNewest.value}
          onClick={toggleNewestFilter}
          onClear={clearNewestFilter}
        />

        <FilterItem
          name={t('component.filter.isOnlineFilterName')}
          isActive={syncModelIsOnline.value}
          onClick={toggleIsOnlineFilter}
          onClear={clearIsOnlineFilter}
        />

        <FilterItem
          name={t('component.filter.withMatchFilterName')}
          iconName={'watermelon'}
          isActive={syncModelWithMatch.value}
          onClick={toggleWithMatchFilter}
          onClear={clearIsWithMatchFilter}
        />

        <FilterItem
          name={t('component.filter.withClearFilters')}
          iconName={'shower'}
          isActive={true}
          onClick={onClearFilters}
        />
      </div>
    );
  },
});
