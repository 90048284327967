import { defineComponent, onMounted, onUnmounted, ref, watch } from 'vue';
import { RouterView, useRouter } from 'vue-router';
import { useEnv, useRouteBuilder } from '@/composables';
import { useAppStore, useAuthStore } from '@/store';
import { useTelegramWebApp } from '@/plugins/telegram';
import DefaultLayout from '@/layouts/Default';
import {
  BtnSize,
  Button,
  Center,
  Loader,
  LoaderSize,
  Toast,
} from '@/components/ui';
import { api } from '@/api';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  setup() {
    const { locale } = useI18n();
    const router = useRouter();
    const appStore = useAppStore();
    const authStore = useAuthStore();
    const tgWebApp = useTelegramWebApp();
    const routeBuilder = useRouteBuilder();
    const isDeveloperMode = useEnv().isDeveloperMode();
    const initialized = ref(false);

    watch(tgWebApp.isReady(), async () => {
      tgWebApp.expandWindow();
      tgWebApp.disableVerticalSwipes();
      tgWebApp.enableClosingConfirmation();
      appStore.applyColorSchema();

      tgWebApp.initLocation();

      const ok = await appStore.authMiddleware();
      if (!ok) {
        initialized.value = true;
        return router.push({ name: 'error.unauthenticated' });
      }

      tgWebApp.onSettingsButtonClick(() => {
        router.push(routeBuilder.settings());
      });
      tgWebApp.showSettingsButton();

      const langCode = authStore.getLanguageCode();
      locale.value = langCode;
      api.setLanguageCode(langCode);

      initialized.value = true;
    });

    let timerId: number;
    const onlineUpdInterval = 60 * 1000;

    onMounted(() => {
      timerId = setInterval(() => {
        authStore.updateOnline();
      }, onlineUpdInterval);
    });

    onUnmounted(() => {
      clearInterval(timerId);
    });

    return () => (
      <DefaultLayout>
        {!initialized.value ? (
          <Center>
            <Loader size={LoaderSize.XL} />
          </Center>
        ) : (
          <>
            <RouterView></RouterView>
            <div id={'modal'}></div>
            <Toast />
            {isDeveloperMode && appStore.mainButton.isShown && (
              <>
                <div class={'min-h-[3rem] z-20'}></div>
                <div class={'fixed right-0 left-0 bottom-0 z-20'}>
                  <Button
                    stretch={true}
                    size={BtnSize.LARGE}
                    onClick={() => appStore.mainButton.cb?.()}
                  >
                    {appStore.mainButton.text}
                  </Button>
                </div>
              </>
            )}
          </>
        )}
      </DefaultLayout>
    );
  },
});
