import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import {
  Container,
  Input,
  InputType,
  InputVariant,
  InputGroup,
  GroupItem,
  Center,
  Loader,
  LoaderSize,
  Avatar,
  AvatarVariant,
  Select,
  SelectItem,
  SelectVariant,
  Text,
  TextColor,
  TextSize,
  Dictionary,
  LazyDictionary,
  DictionaryValue,
  Icon,
  IconSize,
  PhotoPicker,
  UploadFile,
  CountryFlags,
  Modal,
  DESIRE_ICONS,
  IconColor,
} from '@/components/ui';
import { ISetProfileRequest } from '@/api/auth';
import {
  useAppStore,
  useAuthStore,
  useDictionaryStore,
  useToast,
  ToastType,
} from '@/store';
import { useDateFormat, useSemaphore } from '@/composables';
import { useTelegramWebApp } from '@/plugins';

interface UserData {
  name: string;
  birthday: string;
  height: number;
  weight: number;
  city: SelectItem;
  gender: SelectItem;
  sexOrientation: SelectItem;
  desire: SelectItem;
  photos: UploadFile[];
}

export default defineComponent({
  setup: () => {
    const { t } = useI18n();
    const router = useRouter();
    const appStore = useAppStore();
    const authStore = useAuthStore();
    const toastStore = useToast();
    const tgWebApp = useTelegramWebApp();
    const dictionaryStore = useDictionaryStore();
    const semaphore = useSemaphore();

    const formatter = useDateFormat();

    const initialized = ref(false);
    const photoPickerOpened = ref(false);
    let userModel = reactive({} as UserData);

    const userPhoto = computed((): string => {
      if (userModel.photos.length <= 0) {
        return '';
      }
      if (userModel.photos[0].isUploaded) {
        return userModel.photos[0].path as string;
      }
      return '';
    });

    const updateMainButton = () => {
      appStore.showMainButton(t('views.user.profile.saveMainBtn'), async () => {
        const errors = await authStore.updateProfile({
          name: userModel.name,
          birthday: userModel.birthday,
          height: userModel.height,
          weight: userModel.weight,
          cityId: userModel?.city?.key ?? null,
          gender: userModel?.gender?.key ?? null,
          desire: userModel?.desire?.key ?? null,
          sexOrientation: userModel?.sexOrientation?.key ?? null,
          photos: userModel.photos
            .filter((f) => f.id && f.id.length > 0)
            .map((f) => ({ fileId: f.id })),
        } as ISetProfileRequest);

        if (errors.length <= 0) {
          toastStore.add(ToastType.SUCCESS, t('views.user.profile.toastSaved'));
          return;
        }

        const msg = errors.map((err) => err.description).join('. ');
        appStore.showAlert(msg);
      });
    };

    const updateBackButton = () => {
      appStore.showBackButton(() => {
        router.back();
      });
    };

    const updateUserModel = () => {
      const photos = authStore.profile?.photos.map((p) => {
        return {
          id: p.fileId,
          path: p.path,
          isUploaded: true,
        } as UploadFile;
      }) as UploadFile[];

      for (let i = 0; i < 4; i++) {
        if (photos?.[i] === undefined) {
          photos.push({ isUploaded: false });
        }
      }

      userModel = Object.assign(userModel, {
        name: authStore.profile?.name,
        birthday: authStore.profile?.birthday,
        height: authStore.profile?.height,
        weight: authStore.profile?.weight,
        city: {
          key: authStore.profile?.city?.id,
          name: authStore.profile?.city?.name,
        },
        gender: {
          key: authStore.profile?.gender?.slug,
          name: authStore.profile?.gender?.name,
        },
        sexOrientation: {
          key: authStore.profile?.sexOrientation?.slug,
          name: authStore.profile?.sexOrientation?.name,
        },
        desire: {
          key: authStore.profile?.desire?.slug,
          name: authStore.profile?.desire?.name,
        },
        photos: photos,
      });
    };

    const updateButtons = () => {
      updateMainButton();
      updateBackButton();
    };

    onMounted(async () => {
      updateButtons();

      if (typeof authStore.profile !== 'undefined') {
        updateUserModel();
        initialized.value = true;
        return;
      }

      const profileResp = await authStore.loadProfile();
      if (!profileResp.ok) {
        return;
      }

      updateUserModel();
      initialized.value = true;
    });

    onUnmounted(() => {
      appStore.hideBackButton();
    });

    const onLoadCities = async (
      q: string,
      page: number
    ): Promise<SelectItem[]> => {
      const items = await dictionaryStore.searchCityList(q, page);
      return items.map((item) => {
        return {
          key: item.id,
          name: item.name,
          caption: item.country?.name,
          captionImg: CountryFlags[item.country?.code],
        } as DictionaryValue;
      });
    };

    const onLoadGender = async (): Promise<SelectItem[]> => {
      const items = await dictionaryStore.getGenderList();
      return items.map((item) => {
        return { key: item.slug, name: item.name } as SelectItem;
      });
    };

    const onLoadSexOrientation = async (): Promise<SelectItem[]> => {
      const gender = userModel?.gender?.key ?? '';
      const items = await dictionaryStore.getSexOrientationList(gender);
      return items.map((item) => {
        return { key: item.slug, name: item.name } as SelectItem;
      });
    };

    const onLoadDesires = async (): Promise<DictionaryValue[]> => {
      const items = await dictionaryStore.getDesireList();
      return items.map((item) => {
        return {
          key: item.slug,
          name: item.name,
          icon: DESIRE_ICONS[item.slug],
        } as DictionaryValue;
      });
    };

    const onOpenPhotoPicker = () => {
      photoPickerOpened.value = true;
    };

    const onClosePhotoPicker = () => {
      updateButtons();
      photoPickerOpened.value = false;
    };

    const onShareLocation = () => {
      if (!tgWebApp.canUseLocation()) {
        onSetLocationBotV1();
        return;
      }

      tgWebApp.getLocationData((data) => {
        if (data === null) {
          tgWebApp.openLocationSettings();
          return;
        }
        onSetLocation(data.latitude, data.longitude);
      });
    };

    const onSetLocation = (latitude: number, longitude: number) => {
      if (!semaphore.tryAcquire()) {
        return;
      }
      authStore
        .setLocation({
          latitude: latitude,
          longitude: longitude,
        })
        .then(() => {
          toastStore.add(
            ToastType.SUCCESS,
            t('views.user.profile.setGeoSuccess')
          );
        })
        .catch(() => {
          toastStore.add(ToastType.ERROR, t('views.user.profile.setGeoFailed'));
        })
        .finally(() => {
          semaphore.release();
        });
    };

    const onSetLocationBotV1 = () => {
      if (!semaphore.tryAcquire()) {
        return;
      }
      authStore
        .shareLocation()
        .then(() => {
          toastStore.add(
            ToastType.SUCCESS,
            t('views.user.profile.shareGeoSuccess')
          );
        })
        .catch(() => {
          toastStore.add(
            ToastType.ERROR,
            t('views.user.profile.shareGeoFailed')
          );
        })
        .finally(() => {
          semaphore.release();
        });
    };

    watch(
      () => userModel.gender,
      (newVal, oldVal) => {
        if (newVal && oldVal && newVal.key != oldVal.key) {
          userModel.sexOrientation = { key: '', name: '' };
        }
      }
    );

    return () => (
      <>
        {!initialized.value || !authStore.profile ? (
          <Center>
            <Loader size={LoaderSize.XL} />
          </Center>
        ) : (
          <Container>
            <div class={'flex flex-col items-center py-8'}>
              <Avatar
                name={authStore.profile.name || ''}
                photo={userPhoto.value}
                variant={AvatarVariant.PRIMARY_LIGHT}
                onClick={onOpenPhotoPicker}
              >
                <Icon
                  class={TextColor.HIGHLIGHT}
                  name={'photo-solid'}
                  size={IconSize.X36}
                />
              </Avatar>
              <Text
                class={'mt-2'}
                color={TextColor.HIGHLIGHT}
                onClick={onOpenPhotoPicker}
              >
                {t('views.user.profile.avatarDesc')}
              </Text>
            </div>

            <Modal
              isOpen={photoPickerOpened.value}
              onClose={onClosePhotoPicker}
            >
              {{
                content: () => <PhotoPicker v-model={userModel.photos} />,
              }}
            </Modal>

            <div class={'mb-8'}>
              <InputGroup>
                <GroupItem disableHighlight={true}>
                  <Input
                    type={InputType.TEXT}
                    variant={InputVariant.TRANSPARENCY}
                    placeholder={t('views.user.profile.nameFiledPlaceholder')}
                    v-model={userModel.name}
                  />
                </GroupItem>
              </InputGroup>

              <div class={'mt-1 ml-4'}>
                <Text color={TextColor.SECONDARY_DARK} size={TextSize.CAPTION}>
                  {t('views.user.profile.userDesc')}
                </Text>
              </div>
            </div>

            <div class={'mb-8'}>
              <InputGroup>
                <GroupItem>
                  <Select
                    variant={SelectVariant.TRANSPARENCY}
                    placeholder={t('views.user.profile.cityFiledPlaceholder')}
                    v-model={userModel.city}
                  >
                    {{
                      dictionaryList: (
                        isOpen: boolean,
                        selectedItem: SelectItem,
                        onChecked: () => void,
                        onClose: () => void
                      ) => (
                        <Modal
                          isOpen={isOpen}
                          onClose={onClose}
                          onClosed={updateButtons}
                        >
                          {{
                            content: () => (
                              <LazyDictionary
                                selectedItem={selectedItem}
                                onChecked={onChecked}
                                loadDictionaryFn={onLoadCities}
                              />
                            ),
                          }}
                        </Modal>
                      ),
                    }}
                  </Select>
                </GroupItem>
                <GroupItem
                  icon={'map-pin-solid'}
                  iconColor={IconColor.RED}
                  onClick={onShareLocation}
                >
                  {t('views.user.profile.geoBtnName')}
                </GroupItem>
              </InputGroup>

              <div class={'mt-1 ml-4'}>
                <Text color={TextColor.SECONDARY_DARK} size={TextSize.CAPTION}>
                  {t('views.user.profile.locationDesc')}
                </Text>
              </div>
            </div>

            <div class={'mb-8'}>
              <InputGroup>
                <GroupItem disableHighlight={true}>
                  <Input
                    type={InputType.DATE}
                    variant={InputVariant.TRANSPARENCY}
                    placeholder={t(
                      'views.user.profile.birthdayFiledPlaceholder'
                    )}
                    v-model={userModel.birthday}
                    encodeDate={(d: string): string => {
                      return formatter.convertDateToSystemFormat(d);
                    }}
                    decodeDate={(d: string): string => {
                      return formatter.convertSystemDateToUserFormat(d);
                    }}
                  />
                </GroupItem>
                <GroupItem disableHighlight={true}>
                  <Input
                    type={InputType.NUMBER}
                    variant={InputVariant.TRANSPARENCY}
                    placeholder={t('views.user.profile.heightFiledPlaceholder')}
                    pattern="[0-9]*"
                    v-model={userModel.height}
                  />
                </GroupItem>
              </InputGroup>
              <div class={'mt-1 ml-4'}>
                <Text color={TextColor.SECONDARY_DARK} size={TextSize.CAPTION}>
                  {t('views.user.profile.userParamsDesc')}
                </Text>
              </div>
            </div>

            <div class={'mb-8'}>
              <InputGroup>
                <GroupItem>
                  <Select
                    variant={SelectVariant.TRANSPARENCY}
                    title={t('views.user.profile.genderFiledPlaceholder')}
                    v-model={userModel.gender}
                  >
                    {{
                      dictionaryList: (
                        isOpen: boolean,
                        selectedItem: SelectItem,
                        onChecked: () => void,
                        onClose: () => void
                      ) => (
                        <Modal
                          isOpen={isOpen}
                          onClose={onClose}
                          onClosed={updateButtons}
                        >
                          {{
                            content: () => (
                              <Dictionary
                                selectedItem={selectedItem}
                                onChecked={onChecked}
                                loadDictionaryFn={onLoadGender}
                              />
                            ),
                          }}
                        </Modal>
                      ),
                    }}
                  </Select>
                </GroupItem>
                <GroupItem>
                  <Select
                    variant={SelectVariant.TRANSPARENCY}
                    title={t(
                      'views.user.profile.sexOrientationFiledPlaceholder'
                    )}
                    v-model={userModel.sexOrientation}
                  >
                    {{
                      dictionaryList: (
                        isOpen: boolean,
                        selectedItem: SelectItem,
                        onChecked: () => void,
                        onClose: () => void
                      ) => (
                        <Modal
                          isOpen={isOpen}
                          onClose={onClose}
                          onClosed={updateButtons}
                        >
                          {{
                            content: () => (
                              <Dictionary
                                selectedItem={selectedItem}
                                onChecked={onChecked}
                                loadDictionaryFn={onLoadSexOrientation}
                              >
                                {{
                                  emptyList: () => (
                                    <Center>
                                      <div>
                                        {t(
                                          'views.user.profile.genderFirstValidationErr'
                                        )}
                                      </div>
                                    </Center>
                                  ),
                                }}
                              </Dictionary>
                            ),
                          }}
                        </Modal>
                      ),
                    }}
                  </Select>
                </GroupItem>
                <GroupItem>
                  <Select
                    variant={SelectVariant.TRANSPARENCY}
                    title={t('views.user.profile.desireFiledPlaceholder')}
                    v-model={userModel.desire}
                  >
                    {{
                      dictionaryList: (
                        isOpen: boolean,
                        selectedItem: SelectItem,
                        onChecked: () => void,
                        onClose: () => void
                      ) => (
                        <Modal
                          isOpen={isOpen}
                          onClose={onClose}
                          onClosed={updateButtons}
                        >
                          {{
                            content: () => (
                              <Dictionary
                                selectedItem={selectedItem}
                                onChecked={onChecked}
                                loadDictionaryFn={onLoadDesires}
                              />
                            ),
                          }}
                        </Modal>
                      ),
                    }}
                  </Select>
                </GroupItem>
              </InputGroup>
              <div class={'mt-1 ml-4'}>
                <Text color={TextColor.SECONDARY_DARK} size={TextSize.CAPTION}>
                  {t('views.user.profile.userOrientationDesc')}
                </Text>
              </div>
            </div>
          </Container>
        )}
      </>
    );
  },
});
